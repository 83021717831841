<template>
    <div class="news">
        <div class="headers">检索清单</div>
        <div class="content">
            <div style="margin-top: 30px; color: rgba(79,79,79,1);font-size: 18px;font-weight: bold;">添加检索信息</div>
            <el-form :model="userAlertInput" :rules="userAlertInputRules" ref="userAlertInput" style="margin-top: 15px;"
                :inline="true">
                <el-form-item label="检索内容" prop="alertType" style="color: rgba(79,79,79,1);">
                    <el-select v-model="userAlertInput.alertType" placeholder="请选择检索内容" style="width: 120px;"
                        size="small">
                        <el-option value="关键字" label="关键字"></el-option>
                        <el-option value="相关公司" label="相关公司"></el-option>
                        <el-option value="相关行业" label="相关行业"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="检索字段" prop="alertStr" style="margin-left: 20px;color: rgba(79,79,79,1);">
                    <el-input v-model="userAlertInput.alertStr" style="width: 160px;" placeholder="请输入内容"
                        size="small"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button style="margin-left: 40px;" type="primary" size="small"
                        @click="addAlert('userAlertInput')">加入清单</el-button>
                </el-form-item>
            </el-form>
            <div style="margin-top: 20px; color: rgba(79,79,79,1);font-size: 18px;font-weight: bold;">检索清单</div>
            <el-table :data="userNewsAlert">
                <el-table-column prop="alertType" label="检索内容" width="120"></el-table-column>
                <el-table-column prop="alertStr" label="检索字段"></el-table-column>
                <el-table-column prop="updateTime" label="创建时间">
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="min(60%, 1200px)" append-to-body>
            <div
                style=" justify-content: center;display: flex;text-align: left;flex-direction: column;align-items: center;">
                <div style="color: rgba(79,79,79,1);font-size: 18px;font-weight: bold;">修改检索信息</div>
                <el-form :model="userAlertSelect" :rules="userAlertInputRules" ref="userAlertSelect"
                    style="margin-top: 30px;" :inline="true">
                    <el-form-item label="检索内容" prop="alertType" style="color: rgba(79,79,79,1);">
                        <el-select v-model="userAlertSelect.alertType" placeholder="请选择检索内容" style="width: 120px;"
                            size="small">
                            <el-option value="关键词" label="关键字"></el-option>
                            <el-option value="相关公司" label="相关公司"></el-option>
                            <el-option value="相关行业" label="相关行业"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="检索字段" prop="alertStr" style="margin-left: 20px;color: rgba(79,79,79,1);">
                        <el-input v-model="userAlertSelect.alertStr" style="width: 160px;" placeholder="请输入内容"
                            size="small"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button style="margin-left: 40px;" type="primary" size="small"
                            @click="changeUserAlert('userAlertSelect')">确 定</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<style lang="less" scoped>
.news {

    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;


    align-items: center;

    /* 根据需要调整内边距 */
    .headers {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        line-height: 52px;
        font-weight: bold;
        color: rgba(34, 52, 100, 1);
        font-size: 28px;
    }

    .content {
        text-align: left;
    }
}
</style>

<script>
import { mapState, mapMutations } from 'vuex';
import moment from 'moment';
import { GetNewsAlert, InsertNewsAlert, RemoveNewsAlert } from "../api"
import Cookie from 'js-cookie'
export default {
    data() {
        return {
            dialogVisible: false, // 编辑窗体可见性
            userAlertInput: { alertType: "关键词", alertStr: "" },
            userAlertInputRules: {
                alertType: [{ required: true, message: '请选择检索内容', trigger: 'change' }],
                alertStr: [{ required: true, message: '请选择检索字段', trigger: 'change' }],
            },
            inputTxt: '',
            selectValue: '关键词',
            userAlertSelectIndex: 0,
            userAlertSelect: {
                id: 1,
                updateTime: "2024-05-22T17:23:35.1700892",
                alertType: "关键词",
                alertStr: "化工"
            },
        };
    },
    methods: {
        handleEdit(index, row) {
            this.userAlertSelect = JSON.parse(JSON.stringify(row)); // 深克隆对象
            this.userAlertSelectIndex = index;
            this.dialogVisible = true;
        },

        async GetUserAlert() {
            //从数据库获取用户消息提醒内容
            GetNewsAlert().then((data) => {
                if (data.data.code !== 0) {
                    this.ErrorMessage(data.data.message);
                } else {
                    this.$store.commit('setUserNewsAlert', data.data.data);
                }
            })
        },

        //移除指定位置的用户消息提醒内容
        async handleDelete(index, row) {
            this.$confirm('确认删除？')
                .then(async _ => {
                    const newsAlertList = [row.id];
                    try {
                        const data = await RemoveNewsAlert(newsAlertList);
                        if (data.data.message && data.data.message.length > 0) {
                            this.ErrorMessage(data.data.message);
                        } await this.GetUserAlert();
                    } catch (error) {
                        this.ErrorMessage('请求失败');
                    }
                })
                .catch(_ => { });
        },
        //修改指定位置的用户消息提醒内容
        async changeUserAlert(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const newsAlertList = [this.userAlertSelect];
                    try {
                        const data = await InsertNewsAlert(newsAlertList);
                        if (data.data.message && data.data.message.length > 0) {
                            this.ErrorMessage(data.data.message);
                        } await this.GetUserAlert();
                    } catch (error) {
                        this.ErrorMessage('请求失败');
                    }
                    this.dialogVisible = false;
                } else {
                    return false;
                }
            });
        },
        async addAlert(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const newsAlertList = [{
                        id: 0, // 生成新的ID，确保唯一性
                        updateTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'), // 当前时间
                        alertType: this.userAlertInput.alertType,
                        alertStr: this.userAlertInput.alertStr
                    }];
                    try {
                        const data = await InsertNewsAlert(newsAlertList);
                        if (data.data.message && data.data.message.length > 0) {
                            this.ErrorMessage(data.data.message);
                        } await this.GetUserAlert();
                    } catch (error) {
                        this.ErrorMessage('请求失败');
                    }
                    this.inputTxt = ''; // 清空输入框
                    this.selectValue = ''; // 重置选择框
                } else {
                    return false;
                }
            });
        },
        //显示错误信息
        ErrorMessage(message) {
            this.$message({
                showClose: true,
                message: message,
                type: 'error'
            });
        },
        ...mapMutations(['setUserNewsAlert']),
    },
    computed: {
        ...mapState({
            userNewsAlert: state => state.newsAlert.userNewsAlert
        })
    },
    async mounted() {
        await this.GetUserAlert();
    },
    filters: {
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
            }
            return value;
        }
    }
};
</script>
