export default {
    state: {
        userNewsAlert: [],//用户消息提醒
        alertNewsList: [],//用户检索的新闻
        maxAlertNewsIndex: 2//用户检索的新闻的最大页数
    },
    mutations: {
        //设置用户消息提醒内容
        setUserNewsAlert(state, userNewsAlert) {
            state.userNewsAlert = userNewsAlert
        },
        //设置用户检索的新闻
        setAlertNewsList(state, alertNewsList) {
            state.alertNewsList = alertNewsList
        },
        //在开始插入用户检索的新闻
        insertAlertNewsAtFirst(state, insertAlertNewsList) {
            state.alertNewsList.unshift(...insertAlertNewsList);
        },
        //在末尾插入用户检索的新闻
        insertAlertNewsAtEnd(state, insertAlertNewsList) {
            state.alertNewsList.push(...insertAlertNewsList);
        },
        //设置用户检索的新闻
        setMaxAlertNewsIndex(state, maxAlertNewsIndex) {
            state.maxAlertNewsIndex = maxAlertNewsIndex
        },
    }
}