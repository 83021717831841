<template>
    <div>
        <div class="headers">登录/注册</div>
        <el-tabs class="tab" v-model="activeName" stretch>
            <el-tab-pane label="短信验证登录" name="first">
                <el-form ref="loginFormPhoneNumber" :rules="loginRulesPhoneNumber" :model="loginFormPhoneNumber">
                    <el-form-item style="margin-top: 40px;" prop="phoneNumber">
                        <el-input placeholder="请输入手机号" v-model="loginFormPhoneNumber.phoneNumber" clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="verificationCode">
                        <div style="display: flex; align-items: center; margin-top: 30px; width: 100%;">
                            <el-input class="input" placeholder="请输入验证码" v-model="loginFormPhoneNumber.verificationCode"
                                clearable>
                            </el-input>
                            <el-button @click="SendPhoneVerificationCode(loginFormPhoneNumber.phoneNumber)"
                                style="margin-left: 5%;   width: 35%; display: flex;    align-items: center;    justify-content: center;"
                                :disabled="isButtonDisabled" type="primary"> {{
                                    isButtonDisabled ? `${countdown} 秒后重新获取` : '获取验证码' }}</el-button>
                        </div>
                    </el-form-item>
                    <el-button style=" width: 100%; margin-top: 40px;  " type="primary"
                        @click="LoginWithVerificationCode">登录</el-button>
                    <div style="margin-top: 20px;color: rgba(79,79,79,1); width: 100%; text-align: center;">
                        未注册手机验证后自动注册
                    </div>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="账号密码登录" name="second">
                <el-form ref="loginFormPassWord" :model="loginFormPassWord">
                    <el-form-item style="margin-top: 40px;" prop="phoneNumber">
                        <el-input placeholder="请输入手机号" v-model="loginFormPassWord.userName" clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="verificationCode">
                        <el-input style="margin-top: 30px; " placeholder="请输入密码" v-model="loginFormPassWord.passWord"
                            clearable show-password>
                        </el-input>
                    </el-form-item>
                    <el-button style=" width: 100%; margin-top: 40px;  " type="primary"
                        @click="LoginWithPassWord">登录</el-button>
                </el-form>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { sendPhoneVerificationCode, loginWithVerificationCode, loginWithPassword, GetAlertNews } from "../api"
import { mapState, mapMutations } from 'vuex';
import Cookie from 'js-cookie'
export default {
    data() {
        var checkPhoneNumber = (rule, value, callback) => {
            const reg = /^[1][3-9][0-9]{9}$/;
            if (value == '' || value == undefined || value == null) {
                callback();
            } else {
                if ((!reg.test(value)) && value != '') {
                    callback(new Error('请输入正确的手机号码'));
                } else {
                    callback();
                }
            }
        };
        return {
            countdown: 60,
            isButtonDisabled: false,
            activeName: 'first',
            dialogVisible: false,
            loginFormPhoneNumber: {
                phoneNumber: "",
                verificationCode: ""
            },
            loginFormPassWord: {
                userName: "",
                passWord: ""
            },
            loginRulesPhoneNumber: {
                phoneNumber: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入正确的手机号码', trigger: ['blur', 'change'] },
                    { validator: checkPhoneNumber, trigger: ['blur', 'change'] }
                ],
                verificationCode: [
                    { min: 6, max: 6, message: '请输入正确的验证码', trigger: ['blur', 'change'] }, { required: true, message: '请输入验证码', trigger: 'blur' },
                ]
            }
        };
    },
    methods: {
        //发送验证码
        SendPhoneVerificationCode(phoneNumber) {
            if (this.isButtonDisabled || !phoneNumber) {
                this.ErrorMessage("手机号码为空");
                return;
            }
            // 你也可以添加更严格的手机号格式验证
            const phoneRegex = /^1[3-9]\d{9}$/; // 适用于中国的手机号格式
            if (!phoneRegex.test(phoneNumber)) {
                this.ErrorMessage("手机号格式不正确");
                return;
            }
            this.isButtonDisabled = true;
            this.countdown = 60;
            const interval = setInterval(() => {
                this.countdown -= 1;
                if (this.countdown === 0) {
                    clearInterval(interval);
                    this.isButtonDisabled = false;
                }
            }, 1000);
            sendPhoneVerificationCode(phoneNumber).then((data) => {
                console.log(data);
            })
        },
        //验证码登录
        LoginWithVerificationCode() {
            //手机号/验证码为空
            if (!this.loginFormPhoneNumber.phoneNumber && !this.loginFormPhoneNumber.verificationCode) {
                this.ErrorMessage("手机号/验证码为空");
                return;
            }
            // 手机号格式验证
            const phoneRegex = /^1[3-9]\d{9}$/;
            if (!phoneRegex.test(this.loginFormPhoneNumber.phoneNumber)) {
                this.ErrorMessage("手机号格式不正确");
                return;
            }
            loginWithVerificationCode(this.loginFormPhoneNumber.phoneNumber, this.loginFormPhoneNumber.verificationCode).then((data) => {
                if (data.data.code !== 0) {
                    this.ErrorMessage(data.data.message);
                }
                else {
                    //token存入Cookie
                    Cookie.set('token', data.data.data.token, { expires: 31 }); // 保存一月
                    this.setUserInfo(data.data.data.data);
                    if (this.$route.path == '/login') {
                        //跳转首页
                        this.$router.push('/home')
                    } else {
                        this.GetAlertNewsList()
                    }
                    this.setUserLoginDialogVisible(false)
                }
            })
        },
        //密码登录
        LoginWithPassWord() {
            if (!this.loginFormPassWord.userName && !this.loginFormPassWord.passWord) {
                this.ErrorMessage("账号/密码为空");
                return;
            }
            loginWithPassword(this.loginFormPassWord.userName, this.loginFormPassWord.passWord).then((data) => {
                if (data.data.code !== 0) {
                    this.ErrorMessage(data.data.message);
                }
                else {
                    console.log(data.data.data.data);
                    //token存入Cookie
                    Cookie.set('token', data.data.data.token, { expires: 31 }); // 保存一月
                    this.setUserInfo(data.data.data.data);
                    if (this.$route.path == '/login') {
                        //跳转首页
                        this.$router.push('/home')
                    }
                    else {
                        this.GetAlertNewsList()
                    }
                    this.setUserLoginDialogVisible(false)
                }
            })

        },
        //显示错误信息
        ErrorMessage(message) {
            this.$message({
                showClose: true,
                message: message,
                type: 'error'
            });
        },
        GetAlertNewsList() {
            //获取获取用户筛选出来的新闻
            GetAlertNews(this.alertNewsIndex).then((data) => {
                if (data.data.code !== 0) {
                    this.ErrorMessage(data.data.message);
                } else {
                    const newsPreprocessList = data.data.data.alertNewsList.map(item => item.newsPreprocess);
                    this.setAlertNewsList(newsPreprocessList)
                    this.setMaxAlertNewsIndex(data.data.data.count)
                }
            })
        }
        ,
        ...mapMutations(['setUserInfo', 'setUserLoginDialogVisible', 'setAlertNewsList', 'setMaxAlertNewsIndex']),
    },
    computed: {
        ...mapState({
            tags: state => state.tab.userInfo,
            tab: state => state.tab.isCollapse,
        }),

    },
    mounted() {
        //console.log(this.tab, "tags")
        //  this.collapseMenu()
    }
};
</script>

<style lang="less" scoped>
.headers {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: 52px;
    font-weight: bold;
    color: rgba(34, 52, 100, 1);
    font-size: 28px;
}

.tab {
    margin-top: 20px;
    width: 80%;
    margin-left: 10%;
    height: 380px;

    .el-tabs__item {
        font-size: 34px;
    }

    .el-tabs__item.is-active {
        color: rgba(79, 79, 79, 1);
        font-size: 34px;
    }

    .input {
        width: 60%;
    }
}
</style>